$(document).ready(function () {
  ("use strict");

  // Menú: afegim botó i ocultem menú quan hi ha js
  // $("#menu")
  //   .before(
  //     '<button class="hamburger hamburger--spring" type="button" aria-expanded="false" aria-controls="menu"><span class="hamburger-box"><span class="hamburger-inner"></span></span><span class="is-sr-only">Menu</span></button>'
  //   )
  //   .attr("hidden", "true");

  // ----
  // menú (https://polypane.rocks/blog/the-perfect-responsive-menu-2019/)
  // ----
  // var toggleMenu = document.querySelector(".navigation button");
  // var menu = document.querySelector("#menu");

  // if (toggleMenu) {
  //   toggleMenu.addEventListener("click", function () {
  //     var open = JSON.parse(toggleMenu.getAttribute("aria-expanded"));
  //     toggleMenu.setAttribute("aria-expanded", !open);
  //     menu.hidden = !menu.hidden;
  //   });
  // }

  // $(".hamburger").on("click", function () {
  //   // afegir classe al botó
  //   $(this).toggleClass("is-active");
  //   // afegir classe al body
  //   // $("body").toggleClass("js-menu-open");
  // });

  // -------------------------------
  // Menú principal amb desplegables (https://www.w3.org/WAI/tutorials/menus/flyout/#use-button-as-toggle)
  // -------------------------------
  // var menuItems = document.querySelectorAll('.main-nav li.has-submenu');
  // var timer1, timer2;

  // Array.prototype.forEach.call(menuItems, function(el, i){
  //     var activatingA = el.querySelector('a');
  //     var btn = '<button><span><span class="is-sr-only">Mostra el submenú per “' + activatingA.text + '”</span></span></button>';
  //     activatingA.insertAdjacentHTML('afterend', btn);
  //     el.addEventListener("mouseover", function(event){
  //             this.className = "has-submenu open";
  //             this.querySelector('a').setAttribute('aria-expanded', "true");
  //             this.querySelector('button').setAttribute('aria-expanded', "true");
  //             clearTimeout(timer1);
  //     });
  //     el.addEventListener("mouseout", function(event){
  //             timer1 = setTimeout(function(event){
  //                     document.querySelector('.main-nav .has-submenu.open a').setAttribute('aria-expanded', "false");
  //                     document.querySelector('.main-nav .has-submenu.open button').setAttribute('aria-expanded', "false");
  //                     document.querySelector(".main-nav .has-submenu.open").className = "has-submenu";
  //             }, 1000);
  //     });
  //     el.querySelector('button').addEventListener("click",  function(event){
  //         if (this.parentNode.className == "has-submenu") {
  //             this.parentNode.className = "has-submenu open";
  //             this.parentNode.querySelector('a').setAttribute('aria-expanded', "true");
  //             this.parentNode.querySelector('button').setAttribute('aria-expanded', "true");
  //         } else {
  //             this.parentNode.className = "has-submenu";
  //             this.parentNode.querySelector('a').setAttribute('aria-expanded', "false");
  //             this.parentNode.querySelector('button').setAttribute('aria-expanded', "false");
  //         }
  //         event.preventDefault();
  //     });
  //     var links = el.querySelectorAll('a');
  //     Array.prototype.forEach.call(links, function(el, i){
  //         el.addEventListener("focus", function() {
  //             if (timer2) {
  //                 clearTimeout(timer2);
  //                 timer2 = null;
  //             }
  //         });
  //         el.addEventListener("blur", function(event) {
  //             timer2 = setTimeout(function () {
  //                 var opennav = document.querySelector(".main-nav .has-submenu.open")
  //                 if (opennav) {
  //                     opennav.className = "has-submenu";
  //                     opennav.querySelector('a').setAttribute('aria-expanded', "false");
  //                     opennav.querySelector('button').setAttribute('aria-expanded', "false");
  //                 }
  //             }, 10);
  //         });
  //     });
  // });

  // var menuItems = document.querySelectorAll("#menu li.has-submenu");
  // Array.prototype.forEach.call(menuItems, function (el, i) {
  //   var activatingA = el.querySelector("a");
  //   var btn =
  //     '<button><span><span class="is-sr-only">Mostra el submenú per “' +
  //     activatingA.text +
  //     "”</span></span></button>";
  //   activatingA.insertAdjacentHTML("afterend", btn);
  //   // activatingA.setAttribute("aria-expanded", "false");

  //   el.querySelector("button").addEventListener("click", function (event) {
  //     var currentMenu = this.parentNode;
  //     Array.prototype.forEach.call(menuItems, function (el, i) {
  //       if (currentMenu !== el) {
  //         el.classList.remove("open");
  //         el.classList.add("close");
  //         //el.querySelector("a").setAttribute("aria-expanded", "false");
  //         el.querySelector("button").setAttribute("aria-expanded", "false");
  //       }
  //     });
  //     //console.log(this.parentNode.className);
  //     if (this.parentNode.classList.contains("close")) {
  //       this.parentNode.classList.add("open");
  //       this.parentNode.classList.remove("close");
  //       /*this.parentNode
  //         .querySelector("a")
  //         .setAttribute("aria-expanded", "true");*/
  //       this.parentNode
  //         .querySelector("button")
  //         .setAttribute("aria-expanded", "true");
  //     } else {
  //       this.parentNode.classList.add("close");
  //       this.parentNode.classList.remove("open");
  //       /*this.parentNode
  //         .querySelector("a")
  //         .setAttribute("aria-expanded", "false");*/
  //       this.parentNode
  //         .querySelector("button")
  //         .setAttribute("aria-expanded", "false");
  //     }
  //     event.preventDefault();
  //   });
  // });

  // var menuItems = document.querySelectorAll("#main-nav li.has-submenu");
  // Array.prototype.forEach.call(menuItems, function (el, i) {
  //     var activatingA = el.querySelector("a");
  //     var btn =
  //         '<button><span><span class="is-sr-only">Mostra el submenú per “' +
  //         activatingA.text +
  //         '”</span></span></button>';
  //     activatingA.insertAdjacentHTML("afterend", btn);

  //     el.querySelector("button").addEventListener("click", function (event) {
  //         var currentMenu = this.parentNode;
  //         Array.prototype.forEach.call(menuItems, function (el, i) {
  //             if (currentMenu !== el) {
  //                 closeSubmenu(el);
  //             }
  //         });

  //         if (this.parentNode.classList.contains("close")) {
  //             openSubmenu(this.parentNode);
  //         } else {
  //             closeSubmenu(this.parentNode);
  //         }
  //         event.preventDefault();
  //     });

  //     var submenuItems = el.querySelectorAll("ul[aria-label='Submenú'] a");
  //     if (submenuItems.length > 0) {
  //         submenuItems[submenuItems.length - 1].addEventListener("blur", function (event) {
  //             // Check if focus is still within the submenu
  //             setTimeout(function () {
  //                 if (!el.contains(document.activeElement)) {
  //                     closeSubmenu(el);
  //                 }
  //             }, 10);
  //         });
  //     }
  // });

  // function openSubmenu(menuItem) {
  //     menuItem.classList.add("open");
  //     menuItem.classList.remove("close");
  //     menuItem.querySelector("button").setAttribute("aria-expanded", "true");
  // }

  // function closeSubmenu(menuItem) {
  //     menuItem.classList.add("close");
  //     menuItem.classList.remove("open");
  //     menuItem.querySelector("button").setAttribute("aria-expanded", "false");
  // }


  // ---------
  // skip link (https://www.bignerdranch.com/blog/web-accessibility-skip-navigation-links/)
  // ---------

  // bind a click event to the 'skip' link
  $(".skip-link").click(function (event) {
    // strip the leading hash and declare
    // the content we're skipping to
    var skipTo = "#" + this.href.split("#")[1];

    // Setting 'tabindex' to -1 takes an element out of normal
    // tab flow but allows it to be focused via javascript
    $(skipTo)
      .attr("tabindex", -1)
      .on("blur focusout", function () {
        // when focus leaves this element,
        // remove the tabindex attribute
        $(this).removeAttr("tabindex");
      })
      .focus(); // focus on the content container
  });

  // --------
  // Cercador
  // --------
  // $('#searchBtnDesktop').on('click', function () {
  //   setTimeout(function() {
  //     document.getElementById('search1').focus();
  //   }, 100);
  // });

  // ------
  // Pop-up
  // ------
  $('.popup-modal-1').magnificPopup({
    type:'inline',
    closeBtnInside:true,
    autoFocusLast: true,
    focus:"#modal-title-1",
    tClose: "Tancar (Esc)",
  });
  $('.popup-modal-2').magnificPopup({
    type:'inline',
    closeBtnInside:true,
    autoFocusLast: true,
    focus:"#modal-title-2",
    tClose: "Tancar (Esc)",
  });

  // ----------
  // scroll top
  // ----------
  // $(window).scroll(function () {
  //   if ($(this).scrollTop() > 400) {
  //     $(".scrolltop").addClass("visible");
  //   } else {
  //     $(".scrolltop").removeClass("visible");
  //   }
  // });


  // --------
  // headroom
  // --------
  // $(".main-header").headroom(
    // {
    // offset: 0,
    // tolerance: 5,
    // classes: {
    //   initial: "animated",
    //   pinned: "slideDown",
    //   unpinned: "slideUp"
    // }
  // }
  // );

  // -----------
  // File upload
  // -----------
  // $('input[type="file"]').change(function () {
  //   var i = $(this).next().find(".file-label").clone();
  //   var file = $('input[type="file"]')[0].files[0].name;
  //   $(this).next().find(".file-label").text(file);
  // });

});